// Index




// CSS
import '../styles/index.scss';
// JS
// import Lenis from '@studio-freight/lenis'
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { CustomEase } from "gsap/CustomEase";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";





// Svg
// https://parceljs.org/languages/svg/#inlining-as-a-string
import diamondSvg from 'bundle-text:../svg/diamond.svg';
import pendSvg from 'bundle-text:../svg/sqPend.svg';

// It's DOM Time!
document.addEventListener('DOMContentLoaded', function () {

    // Register GSAP
    gsap.registerPlugin(CustomEase, MorphSVGPlugin, ScrollTrigger, Draggable);

    // Inject diamond Svg
    const diamondSpans = document.querySelectorAll('#diamond span');
    diamondSpans.forEach(function (diamondSpan) {
        diamondSpan.innerHTML = diamondSvg;
    });
    const reflectionSpans = document.querySelectorAll('#reflection span');
    reflectionSpans.forEach(function (reflectionSpan) {
        reflectionSpan.innerHTML = diamondSvg;
    });
    // const pendSpans = document.querySelectorAll('#sq__pend span');
    // pendSpans.forEach(function (pendSpan) {
    //     pendSpan.innerHTML = pendSvg;
    // });

    gsap.set("#diamond .diamondOne", {
        autoAlpha: 1,
        scaleY: 0.5,
        scaleX: 0.25,
        transformOrigin: "center"
    });
    gsap.set("#reflection .circleOne", {
        autoAlpha: 1,
        scaleY: 0.125,
        scaleX: 0.125,
        transformOrigin: "center"
    });


    // const diamondtl = gsap.timeline();
    // diamondtl
    //     .to(".diamondOne", 0.25, {
    //         scaleX: 0.5,
    //         scaleY: 0.5,
    //         morphSVG: ".circleOne",
    //         yoyo: true,
    //         repeat: -1,
    //         repeatDelay: 1,
    //     }, 1);



    // Drag Images
    // let itemShell = document.getElementsByClassName("__ii")[0];
    let imageShells = gsap.utils.toArray('.__space');
    imageShells.forEach(shell => {
        Draggable.create(shell, {
            type: "x,y",
            edgeResistance: 1.5,
            throwProps: true,
            allowEventDefault: true,
            bounds: "main",
            onDrag: function () {
                shell.className = "item__shell active";
                gsap.to(shell, {
                    ease: "none",
                    scaleY: 0.9,
                    duration: 0.1,
                    scaleX: 0.9,
                    borderColor: "black",
                });
            },
            onDragEnd: function () {
                shell.className = "item__shell";
                gsap.to(shell, {
                    ease: "none",
                    scaleY: 1,
                    duration: 0.1,
                    borderColor: "hsl(43, 100%, 57%)",
                    scaleX: 1,
                });
            },
        });
    })

    // Drag Images
    // let itemShell = document.getElementsByClassName("__ii")[0];
    let imageWins = gsap.utils.toArray('.window');
    imageWins.forEach(win => {
        Draggable.create(win, {
            type: "x,y",
            edgeResistance: 1.5,
            throwProps: true,
            allowEventDefault: true,
            bounds: "main",
            onDrag: function () {
                // win.className = "window buy__window active";
                gsap.to(win, {
                    ease: "none",
                    scaleY: 0.9,
                    duration: 0.1,
                    scaleX: 0.9,
                    borderColor: "black",
                });
            },
            onDragEnd: function () {
                // win.className = "window buy__window";
                gsap.to(win, {
                    ease: "none",
                    scaleY: 1,
                    duration: 0.1,
                    borderColor: "hsl(43, 100%, 57%)",
                    scaleX: 1,
                });
            },
        });
    })
    let otherWins = gsap.utils.toArray('.otherThings');
    otherWins.forEach(oth => {
        Draggable.create(oth, {
            type: "x,y",
            edgeResistance: 1.5,
            throwProps: true,
            allowEventDefault: true,
            bounds: "main",
            onDrag: function () {
                oth.className = "sun otherThings active";
                gsap.to(oth, {
                    ease: "none",
                    scaleY: 0.9,
                    duration: 0.1,
                    scaleX: 0.9,
                });
            },
            onDragEnd: function () {
                oth.className = "sun otherThings";
                gsap.to(oth, {
                    ease: "none",
                    scaleY: 2,
                    duration: 0.1,
                    scaleX: 2,
                });
            },
        });
    })

    let diaWins = gsap.utils.toArray('.dia');
    diaWins.forEach(dia => {
        Draggable.create(dia, {
            type: "x,y",
            edgeResistance: 2.5,
            throwProps: true,
            allowEventDefault: true,
            bounds: "main",
            onDrag: function () {
                dia.className = "dia active";
                gsap.to(dia, {
                    ease: "none",
                    scaleY: 0.9,
                    duration: 0.1,
                    fill: "magenta",
                    scaleX: 0.9,
                });
            },
            onDragEnd: function () {
                dia.className = "dia";
                gsap.to(dia, {
                    ease: "none",
                    scaleY: 2,
                    fill: "purple",
                    duration: 0.1,
                    scaleX: 2,
                });
                gsap.to(".circleOne", {
                    duration: 0.1,
                    fill: "magenta",
                });
            },
        });
    })

    // Drag Images
    // let itemShell = document.getElementsByClassName("__ii")[0];
    let imageDiamond = gsap.utils.toArray('#diamond');
    imageDiamond.forEach(DiamondImage => {
        Draggable.create(DiamondImage, {
            type: "x,y",
            edgeResistance: 1,
            throwProps: true,
            allowEventDefault: true,
            bounds: "article",
            onDrag: function () {
                DiamondImage.className = "item__shell active";
                gsap.to("#mast #diamond .diamondOne", {
                    ease: "none",
                    fill: "hsl(43, 100%, 43%)",
                    opacity: 0.4,
                    duration: 0.125,
                    morphSVG: "#mast  #diamond .circleOne",
                });
                gsap.to("#mast #diamond span", {
                    scaleY: 0.5,
                    duration: 0.1,
                    scaleX: 0.5,
                })
            },
            onDragEnd: function () {
                DiamondImage.className = "item__shell";
                gsap.to("#mast #diamond .diamondOne", {
                    ease: "none",
                    opacity: 1,
                    fill: "magenta",
                    duration: 0.125,
                    morphSVG: "#mast #diamond .diamondOne",
                });
                gsap.to("#mast #diamond span", {
                    scaleY: 1,
                    duration: 0.25,
                    scaleX: 1,
                })
            },
        });
    })




    // Scrolling 
    // const lenis = new Lenis({
    //     duration: 1,
    //     easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    // })
    // function raf(time) {
    //     lenis.raf(time);
    //     ScrollTrigger.update();
    //     requestAnimationFrame(raf)
    // }
    // requestAnimationFrame(raf)



    let viewport = document.querySelector("body");

    ScrollTrigger.matchMedia({
        // large
        "(min-width: 600px)": function () {
            viewport.classList.add("desktop");
        },

        // small
        "(max-width: 599px)": function () {
            gsap.set(viewport, { clearProps: "all" });
            viewport.classList.remove("desktop");
        },
    });



    // Sean's big Heading
    var textWrapper = document.querySelector('#sean');
    textWrapper.innerHTML = textWrapper.textContent.replace(/(\S*)/g, m => {
        return `<span class="word">` +
            m.replace(/(-|#|@)?\S(-|#|@)?/g, "<span class='letter'>$&</span>") +
            `</span>`;
    });
    let seanLetters = gsap.utils.toArray('#sean .word .letter');
    // gsap.set(seanLetters, {
    //     transformOrigin: "center",
    //     scale: 1,
    //     y: 20,
    // });
    const seanLettersTl = gsap.timeline({})
    seanLettersTl
        .add("start")
        .to(seanLetters, {
            autoAlpha: 1,
            scale: 1,
            duration: 1,
            y: 211,
            yoyo: true,
            stagger: {
                each: 0.125,  // stagger each by 0.1 seconds (or use amount for an overall value to distribute)
                repeat: 1, // <-- LOOK! It's nested, so each sub-tween will repeat independently
                yoyo: true  // again, passed to each sub-tween.
            },

        }, "start+=6")

        .to("#sean", {
            y: 111,
            duration: 1,
            ease: "none",
        }, "start+=7")
        .to("#mast #diamond .diamondOne", {
            ease: "none",
            scaleY: 1,
            duration: 1,
            scaleX: 1,
            morphSVG: "#mast #diamond .circleOne",
        }, "start+=7")
        .to(".imgOne", {
            y: "+=50%",
            duration: 3,
            ease: "none",
        }, "start+=6")
        .to(".desktop #new .window", {
            y: "+=110%",
            duration: 2.5,
            ease: "none",
        }, "start+=6")
        .to("#reflection .circleOne", {
            ease: "none",
            scaleY: 0.3,
            duration: 1,
            scaleX: 0.125,
            morphSVG: "#reflection .diamondOne",
        }, "start+=8")
        .to(".sun", {
            y: -300,
            duration: 2,
            ease: "none",
        }, "start+=8")
        // .to("#profile__photo", {
        //     y: -11,
        //     duration: 3,
        //     ease: "none",
        // }, "start+=8")
        // .to(".profile__heading h2", {
        //     y: 41,
        //     duration: 3,
        //     ease: "none",
        // }, "start+=8")

        ;



    const stTwo = ScrollTrigger.create({
        animation: seanLettersTl,
        trigger: '#mast',
        start: 'top 90%',
        end: 'bottom',
        scrub: true
    })
    // ScrollTrigger: {
    //     trigger: '#books',
    //     start: 'top bottom',
    //     end: 'top bottom',
    //     scrub: true,
    // },
    // gsap.to(".diamondOne", { 
    //     y: -26, 
    //     ease: "none",
    //     transformOrigin: "center",
    //     ScrollTrigger: {
    //         trigger: "#books",
    //         start: "top bottom",
    //         end: "top bottom",
    //         scrub: true,
    //     },    
    // });

    // Buy "Do you remember being born?"

    // Close Trick
    const closeSesame = document.getElementById('close-button');
    let popToggle = gsap.timeline({});
    closeSesame.onclick = function () {
        popToggle
            .add("popToggleClick")
            .to(".window", {
                x: '-1100vh',
                ease: "back.in(0.75, 1)",
                duration: 0.5,
                delay: 0.1,
            }, "popToggleClick")
            .to(".window", {
                x: '100vh',
                delay: 3,
                duration: 0,
            })
            .to(".book__shop", {
                display: "none",
                ease: 'none',
                scaleY: 0,
                duration: 0,
                delay: 0,
            })
            .to(".buy__window .window__contents", {
                opacity: 1,
                duration: 0,
            })
            .to(".window", {
                x: 0,
                ease: "back.out(0.5, 1.5)",
                duration: 0.25,
            })
            ;
    };

    // Close Trick
    const closeSesameTwo = document.getElementById('close-button-two');
    let popToggleTwo = gsap.timeline({});
    closeSesameTwo.onclick = function () {
        popToggleTwo
            .add("popToggleClick")
            .to(".buy__window__do", {
                x: '-1100vh',
                ease: "back.in(0.75, 1)",
                duration: 0.5,
                delay: 0.1,
            }, "popToggleClick")
            .to(".buy__window__do", {
                x: '100vh',
                delay: 3,
                duration: 0,
            })
            .to(".buy__window__do", {
                x: 0,
                ease: "back.out(0.5, 1.5)",
                duration: 0.25,
            })
            ;
    };
    // Close Trick
    const closeSesameThree = document.getElementById('close-button-three');
    let popToggleThree = gsap.timeline({});
    closeSesameThree.onclick = function () {
        popToggleThree
            .add("popToggleClick")
            .to(".buy__window__wagers", {
                x: '-1100vh',
                ease: "back.in(0.75, 1)",
                duration: 0.5,
                delay: 0.1,
            }, "popToggleClick")
            .to(".buy__window__wagers", {
                x: '100vh',
                delay: 3,
                duration: 0,
            })
            .to(".buy__window__wagers", {
                x: 0,
                ease: "back.out(0.5, 1.5)",
                duration: 0.25,
            })
            ;
    };
    // Close Trick
    const closeSesameFour = document.getElementById('close-button-four');
    let popToggleFour = gsap.timeline({});
    closeSesameFour.onclick = function () {
        popToggleFour
            .add("popToggleClick")
            .to(".buy__window__UsConductors", {
                x: '-1100vh',
                ease: "back.in(0.75, 1)",
                duration: 0.5,
                delay: 0.1,
            }, "popToggleClick")
            .to(".buy__window__UsConductors", {
                x: '100vh',
                delay: 3,
                duration: 0,
            })
            .to(".buy__window__UsConductors", {
                x: 0,
                ease: "back.out(0.5, 1.5)",
                duration: 0.25,
            })
            ;
    };

    // Open Book Shop
    const buySesame = document.getElementById('buyDoYou');
    let popSesame = gsap.timeline({});
    buySesame.onclick = function () {
        popSesame
            .add("popToggleClick")
            .to(".book__shop", {
                display: "block",
                ease: 'none',
                duration: 0,
                delay: 0,
            }, "popToggleClick")
            .to(".buy__window .window__contents", {
                opacity: 0,
                duration: 0.25,
            }, "popToggleClick")
            .to(".book__shop", {
                scaleY: 1,
                autoAlpha: 1,
                duration: 0.075,
            }, "popToggleClick")
            .to(".book__shop > div", {
                opacity: 1,
                duration: 0.25,
            })
            ;
    };

    // Open 'Do You... 2"
    const buySesameTwo = document.getElementById('buyDYRBBTwo');
    let popSesameTwo = gsap.timeline({});
    buySesameTwo.onclick = function () {
        popSesameTwo
            .add("popToggleClick")
            .to(".buy__window__do", {
                display: "block",
                ease: 'none',
                duration: 0,
                delay: 0,
            }, "popToggleClick")
            .to(".buy__window__do .book__shop", {
                display: "block",
                ease: 'none',
                duration: 0,
                delay: 0,
            }, "popToggleClick")
            .to(".buy__window__do", {
                opacity: 1,
                duration: 0.125,
                y: -10,
            })
            .to(".buy__window__do .book__shop", {
                scaleY: 1,
                autoAlpha: 1,
                duration: 0.075,
            }, "popToggleClick")
            .to(".buy__window__do .book__shop > div", {
                opacity: 1,
                duration: 0.25,
            })
    };

    // Open 'The Wagers"
    const buySesameThree = document.getElementById('buyTheWagers');
    let popSesameThree = gsap.timeline({});
    buySesameThree.onclick = function () {
        popSesameThree
            .add("popToggleClick")
            .to(".buy__window__wagers", {
                display: "block",
                ease: 'none',
                duration: 0,
                delay: 0,
            }, "popToggleClick")
            .to(".buy__window__wagers .book__shop", {
                display: "block",
                ease: 'none',
                duration: 0,
                delay: 0,
            }, "popToggleClick")
            .to(".buy__window__wagers", {
                opacity: 1,
                duration: 0.125,
                y: -10,
            })
            .to(".buy__window__wagers .book__shop", {
                scaleY: 1,
                autoAlpha: 1,
                duration: 0.075,
            }, "popToggleClick")
            .to(".buy__window__wagers .book__shop > div", {
                opacity: 1,
                duration: 0.25,
            })
    };

    // Open 'Us Conductors"
    const buySesameFour = document.getElementById('buyUsConductors');
    let popSesameFour = gsap.timeline({});
    buySesameFour.onclick = function () {
        popSesameFour
            .add("popToggleClick")
            .to(".buy__window__UsConductors", {
                display: "block",
                ease: 'none',
                duration: 0,
                delay: 0,
            }, "popToggleClick")
            .to(".buy__window__UsConductors .book__shop", {
                display: "block",
                ease: 'none',
                duration: 0,
                delay: 0,
            }, "popToggleClick")
            .to(".buy__window__UsConductors", {
                opacity: 1,
                duration: 0.125,
                y: -10,
            })
            .to(".buy__window__UsConductors .book__shop", {
                scaleY: 1,
                autoAlpha: 1,
                duration: 0.075,
            }, "popToggleClick")
            .to(".buy__window__UsConductors .book__shop > div", {
                opacity: 1,
                duration: 0.25,
            })
    };


    // Scroll Nav

    // Books Section
    var booksLink = document.querySelector('#booksLink');
    gsap.to(booksLink, {
        scrollTrigger: {
            trigger: "#books",
            start: "top 200px",
            end: "bottom 200px",
            toggleClass: { targets: booksLink, className: "--active" },
        }
    });
    // Bio Section
    var bioLink = document.querySelector('#bioLink');
    gsap.to(bioLink, {
        scrollTrigger: {
            trigger: "#profile",
            start: "top 200px",
            end: "bottom 200px",
            toggleClass: { targets: bioLink, className: "--active" },
        }
    });
    // Other Writing Section
    var otherLink = document.querySelector('#otherLink');
    gsap.to(otherLink, {
        scrollTrigger: {
            trigger: "#otherWriting",
            start: "top 200px",
            end: "bottom 200px",
            toggleClass: { targets: otherLink, className: "--active" },
        }
    });


    // Speaking Section
    var eventsLink = document.querySelector('#speakingLink');
    gsap.to(eventsLink, {
        scrollTrigger: {
            trigger: "#speaking",
            start: "top 200px",
            end: "bottom 200px",
            toggleClass: { targets: eventsLink, className: "--active" },
        }
    });

    // Events Section
    var eventsLink = document.querySelector('#eventsLink');
    gsap.to(eventsLink, {
        scrollTrigger: {
            trigger: "#events",
            start: "top 200px",
            end: "bottom",
            toggleClass: { targets: eventsLink, className: "--active" },
        }
    });

    // const diamondSpans = document.querySelectorAll('#diamond span');
    // document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    //     anchor.addEventListener('click', function (e) {
    //         e.preventDefault();
    //         lenis.scrollTo(this.getAttribute('href'))
    //     });
    // })

}, false);